@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  padding: 0;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
  padding: 0;
}
.about {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
  padding: 0;
}

.footer {
  background-color: #191919;
}

/* CSS */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #f0443d;
  color: #fff;
  border: none;
  border-radius: 24px;
  padding: 8px 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #d03b30;
}

/* .cta {
  color: black;
} */
